import React from 'react';
import { Typography } from '@mui/material';
import './seniorgym.css'; // Import your CSS file
import SGimage from '../../src/static/images/seniorgym.jpg';
import Waltraud from './Teachers/Waltraud';
import HalleAach from './HalleAach';
import WhereCard from './Map/WhereCard';

const SeniorGym = () => {
  return (
    <div className="container-sport-senior">
      <div className="content-sport-senior">
        <div className="col">
          <img src={SGimage} alt="Part 1" />
          <div className="text">
            <h1 className='title-sport-senior'>
            Kinderturnen Laufalter bis 2,5 Jahre
            </h1>
            <p>
            Diese Stunde fördert bei den Allerkleinsten ab Laufalter spielerich die Entwicklung von Körper und Geist, Geschicklichkeit, Balance, Körpererfahrung und ein wenig Selbstvertrauen in das eigene Handeln.
            </p>
            <h1 className='title-sport-senior'>
            Kinderturnen 2,5-3,5 Jahre
            </h1>
            <p>
            In dieser Gruppe wird Spaß und Freude an der Bewegung im Spiel vermittelt. Kleinere Geschicklichkeitsübungen am Anfang der Stunde werden als Aufgabenstellung mit oder ohne Hilfe eines Elternteils erarbeitet. Danach ist selbstverständlich Klettern und Balancieren angesagt.
            </p>
          </div>
        </div>

        <div className="col">
        <div className="text">
            <h1 className='title-sport-senior'>
            Kinderturnen 3,5-4,5 Jahre
            </h1>
            <p>
            Damit Sport allen Kindern Spaß macht werden in dieser Stunde verschiedene Übungen spielerisch erarbeitet. Durch unterschiedliche Bewegungsanforderungen wird die Konzentration und Ausdauer verbessert. Die Übungen und Spiele in der Gruppe fördern gleichzeitig auch schon die Anfänge des Sozialverhaltens der Kinder.
            </p>
            <h1 className='title-sport-senior'>
            Kinderturnen 4,5 Jahre bis Schulalter
            </h1>
            <p>
            In dieser Stunde werden vorrangig die sozialen Kompetenzen gefördert und geschult. Beispielsweise lernen die Kinder beim Spielen/Turnen mit Gleichaltrigen nachzugeben und zu kooperieren. Die motorischen Fähigkeiten z.B.hüpfen, werfen oder balancieren stehen dabei auch im Fokus und fördern den Gleichgewichtssinn. Auch die kognitiven Fähigkeiten wie  z.B. Konzentrations- und Kooperationsfähigkeit wird trainiert.
            </p>
          </div>
          <img src={SGimage} alt="Part 2" />
        </div>

        <div className='sport-details'>
        <div className='card-details'>
          <Waltraud />
        </div>
        <div className='card-details'>
          <WhereCard />
        </div>
        </div>
      </div>
    </div>
  );
};

export default SeniorGym;
