import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker'; // Import DatePicker
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import axios from 'axios';
import Alert  from 'react-bootstrap/Alert';

class EditableTable extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      data: 
    //   {
    //     username: 'john_doe',
    //     name: 'John',
    //     surname: 'Doe',
    //     age: 30,
    //   },
      { ...props.user },
      isEditing: false,
      success: null
    };
  }

  handleInputChange(key, value) {
    // Adjust the date to the local time zone
    if (key === 'birthday' && value) {
        const date = new Date(value);
        date.setUTCHours(0, 0, 0, 0); // Set time to midnight in UTC
        value = date.toISOString();

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        // value = date.toLocaleDateString('de-DE', options);

        console.log("birthday::::", value)
      }
      
  this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [key]: value,
      },
    }));
  }

  toggleEditMode() {
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing,
    }));
  }

   submitChanges () {
    // Add code to send data to the backend
    // For example, use Axios or fetch to make an HTTP request
    const { data } = this.state;
    console.log("changes");
    this.setState((prevState) => ({
        success: "Profile updated!"
    }))

    // const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
        console.log("birth >>>", data);
        axios.post('http://localhost:8000/api/database/users', { username: data.username, name: data.name, surname: data.surname, email: data.email, sport: data.sport, birthday: new Date(data.birthday)});
        // alert('User updated successfully');
        this.setState({ isEditing: false });
        // window.location.reload();
    } catch (error) {
        console.error(error);
        alert('An error occurred');
    }

  }

  renderTableRows() {
    const { data, isEditing } = this.state;
    const dropdownOptions = ["foot", "judo", "karate", "Kinderturnen"]
    console.log(data);

    return Object.keys(data).map((key) => {
        // Exclude the row with key='_id' from being displayed
        if (key !== '_id') {
          return (
            <tr key={key}>
              <td>{key}</td>
              <td>
                {isEditing ? (
                  key === 'birthday' ? (
                    <DatePicker
                      selected={new Date(data[key])}
                      onChange={(date) =>
                        this.handleInputChange(key, date.toISOString())
                      }
                    />
                    ) : key === 'sport' ? (
                        <select
                          value={data[key]}
                          onChange={(e) => this.handleInputChange(key, e.target.value)}
                        >
                          {dropdownOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
              
                  ) : (
                    <input
                      type="text"
                      value={data[key]}
                      onChange={(e) => this.handleInputChange(key, e.target.value)}
                      style={{ width: '100%' }}
                    />
                  )
                ) : (
                  data[key]
                )}
              </td>
            </tr>
          );
        }
        return null; // Return null for rows you want to exclude
      });
      } 

  render() {
    return (
      <div>
        <Table responsive="md">
          <tbody>{this.renderTableRows()}</tbody>
        </Table>
        {this.state.success && <Alert variant="success">{this.state.success}</Alert>}

        <Button variant="primary" onClick={() => this.toggleEditMode()}>
          {this.state.isEditing ? 'Cancel' : 'Edit'}
        </Button>
        {this.state.isEditing ? (
          <Button variant="success" onClick={() => this.submitChanges()}>
            Submit
          </Button>
        ) : null}

      </div>
    );
  }
}

export default EditableTable;
