import React, { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AuthApi = React.createContext();
const TokenApi = React.createContext();

const Logout = () => {
    const nav = useNavigate();

    Cookies.remove("token");
    localStorage.clear()
    
    const handleSubmit = async (evt) => {
        evt.preventDefault();
        nav("/home");
        window.location.reload();
      }

    const handleKeyDown = async (evt) => {
        evt.preventDefault();
        nav("/home");
        window.location.reload();
      }

    return (
        <>
            <div>
            <Alert key="success" variant="success">
                <Container>
                <Row>
                    <Col><h3>Successfully logged out</h3></Col>
                    <Col><Button type="submit" variant="success" onKeyDown={(e) => handleKeyDown} onClick={(e) => handleSubmit(e)}>Continue</Button></Col>
                </Row>
                </Container>      
            </Alert>
            </div>

        </>

    )
};
export default Logout;
