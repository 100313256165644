import {
    signInWithGoogle,
    signInWithEmailAndPassword,
    registerWithEmailAndPassword
  } from "../services/firebase";
  import Form from "react-bootstrap/Form";
  import Button from "react-bootstrap/Button";
  import Card from "react-bootstrap/Card";
  import Alert  from 'react-bootstrap/Alert';
  import { Container, Row, Col } from 'react-bootstrap';

  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";

  import "../App.css";
  
  const Login = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState('');
  
    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const nav = useNavigate();

    const handleLogin = async () => {
        const errorMessage = await signInWithEmailAndPassword(
          loginEmail,
          loginPassword
        );
    
        if (errorMessage) {
          setError(errorMessage);
        } else {
          setError(null);
          nav("/profile")

        }
      };

    const GoogleLogin = () =>{
        signInWithGoogle()
        console.log("Google");
        nav("/profile")
    }

    return (
      <div className="container-fluid" style={{ marginTop: "10%" }}>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                <Card>
                  <Card.Body>
                    <Card.Title>User Login</Card.Title>

                    <div>
                    {error && <Alert variant="danger">{error}</Alert>}
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>E-Mail-Adresse </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={loginEmail}
                          onChange={(e) => setLoginEmail(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text>
                      </Form.Group>
  
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={loginPassword}
                          onChange={(e) => setLoginPassword(e.target.value)}
                        />
                      </Form.Group>
                      <hr style={{ margin: "5%" }} />
  
                      <div className="d-grid gap-2">
                        <Button
                          variant="outline-success"
                          type="submit"
                          onClick={handleLogin}
                        >
                          Login
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={GoogleLogin}
                        >
                          <i className="fab fa-google"></i>Sign-in with Google
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                    </Col>
                    <Col xs={12} md={6}>
                <Card>
                  <Card.Body>
                    <Card.Title>User Registration</Card.Title>
                    <Card.Text>First time visiting? register Here</Card.Text>
                    <div>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="name"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
  
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <hr style={{ margin: "5%" }} />
  
                      <div className="d-grid gap-2">
                        <Button
                          variant="outline-danger"
                          type="submit"
                          onClick={() => {
                            registerWithEmailAndPassword(name, email, password);
                          }}
                        >
                          Register
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                    </Col>
                </Row>
            </Container>

            </div>

    );
  };
  
  export default Login;