import * as React from 'react';
import Typography from '@mui/material/Typography';
import WhatsAppShareButton from '../Share/WhatsApp.js';

export default function Waltraud() {
  return (
    <div>
      <h2 className='teacher-title'>
      Trainer für:
      </h2>
      <Typography variant="body2" color="text.secondary">
        <p className='links-sport'>
          <a href='/sports/kinderturnen'>Kinderturnen</a>
        </p> 
        <p className='links-sport'>
        <a href='/sports/seniorgym'>Seniorengymnastik</a>
        </p> 
      </Typography>
      <WhatsAppShareButton />
    </div>
  );
}