import React, { useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';

const PostsPagination = ({ totalPosts, postsPerPage, currentPage, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPaginationItems = () => {
    const maxVisiblePages = 5; // Adjust the number of visible page links as needed

    const items = [];
    let startPage = currentPage - Math.floor(maxVisiblePages / 2);
    let endPage = currentPage + Math.floor(maxVisiblePages / 2);

    if (startPage < 1) {
      startPage = 1;
      endPage = startPage + maxVisiblePages - 1;
    }

    if (endPage > pageNumbers.length) {
      endPage = pageNumbers.length;
      startPage = endPage - maxVisiblePages + 1;
    }

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => onPageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="startEllipsis" />);
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < pageNumbers.length) {
      if (endPage < pageNumbers.length - 1) {
        items.push(<Pagination.Ellipsis key="endEllipsis" />);
      }
      items.push(
        <Pagination.Item
          key={pageNumbers.length}
          onClick={() => onPageChange(pageNumbers.length)}
        >
          {pageNumbers.length}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Container fluid>
      <div className="pagination-container d-flex justify-content-center">
        <Pagination>{renderPaginationItems()}</Pagination>
      </div>
    </Container>
  );
};

export default PostsPagination;
