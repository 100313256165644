import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink} from 'react-router-dom';
import LogoImage from '../../static/images/svaach_logo.png';
import { auth } from "../../services/firebase";

const pages = [
    {text: 'Über uns', link: '/about'}, 
    {text: 'Kontakt', link: '/contact'}, 
    {text: 'News', link: '/news'}, 
    {text: 'Kalendar', link: '/calendar'}, 
    {text: 'Sport', link: '/sports'}
];
const settings = [
    {text: 'Profile', link: '/profile'},
    {text: 'Logout', link: '/logout'}];

function NavbarPerso(props) {

    const userAuth = props.user;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseUserMenuLogOut = () => {
    setAnchorElUser(null);
    auth.signOut()
  };

  return (
        <AppBar position="static">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <img src={LogoImage} alt="Logo" style={{ height: 40, marginRight: 10 }} />
            </RouterLink>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/about"
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                SV AACH
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                >
                <MenuIcon />
                </IconButton>
                <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
                >
                {pages.map((page, index) => (
                    <MenuItem key={index} onClick={handleCloseNavMenu} component={RouterLink} to={page.link}>
                    <Typography textAlign="center">{page.text}</Typography>
                    </MenuItem>
                ))}
                </Menu>
            </Box>
            <Typography
                variant="h5"
                noWrap
                component="a"
                href="/about"
                sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                SV AACH
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    component={RouterLink}
                    to={page.link}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    {page.text}
                </Button>
                ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src={userAuth.photoURL} />
                </IconButton>
                </Tooltip>
                <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                <MenuItem key='profile' onClick={handleCloseUserMenu} component={RouterLink} to={'/profile'}>
                <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem key='logout' onClick={handleCloseUserMenuLogOut} component={RouterLink} to={'/logout'}>
                <Typography textAlign="center">Logout</Typography>
                </MenuItem>

                </Menu>
            </Box>
            </Toolbar>
        </Container>
        </AppBar>

  );
}
export default NavbarPerso;