import * as React from 'react';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function Heiko() {
  return (
    <div>
      <h2 className='desc-title'>
      Facebook page
      </h2>
      <Typography variant="body2" color="text.secondary">
        <p className='links-sport'>
          <a href='https://www.facebook.com/sgabnofficial'><FacebookIcon /> SG Aach/Butzweiler/Newel</a>
        </p> 
      </Typography>
    </div>
  );
}