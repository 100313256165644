import React from "react";
import { motion, Variants } from "framer-motion";
import Aach from '../../src/static/images/Aach.jpg';

const About = () => {

const cardVariants = {
  offscreen: {
    y: 600,
	// x: -600
  },
  onscreen: {
    y: 50,
	// x: 0,
    // rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.1,
      duration: 1.8
    }
  }
};


function Card({ emoji, description, colorStart, colorEnd }) {
  // const background = `${color}`;
  const background = `linear-gradient(${colorStart}, ${colorEnd})`;

  return (
	<div className="root-animation">
		<motion.div
		className="card-container-animation"
		initial="offscreen"
		whileInView="onscreen"
		viewport={{ once: true, amount: 0.9 }}
		>
		<div className="splash" style={{ background }} />
      <motion.div className="card-animation" variants={cardVariants}>
        <div className="break">
        <h2>{emoji}</h2>
        <p>{description}</p>
        <div id='image' className="img-animation">
            <img src={Aach} alt="Aach" />
        </div>
      </div>
		</motion.div>
		</motion.div>
	</div>
  );
}

const food = [
  ["HERZLICH WILLKOMMEN", "Willkommen auf der Vereinsseite des SV Aach. Hier finden Sie Informationen über aktuelle Kurse und vieles mehr.", 'grey','#181719'],
  ["🍊", "Explications 1", 'grey','#181719'],
  ["🍋", "Explications 1", 'grey','#181719'],
  ["🍐", "Explications 1", 'grey','#181719'],
  ["🍏", "Explications 1", 'grey','#181719'],
  ["🫐", "Explications 1", 'grey','#181719'],
  ["🍆", "Explications 1", 'grey','#181719'],
  ["🍇", "Explications 1", 'grey','#181719']
];

  return food.map(([emoji, description, colorStart, colorEnd]) => (
    <Card emoji={emoji} description={description} colorStart={colorStart} colorEnd={colorEnd} key={emoji} />
  ));
}
export default About;
