import React from "react"
import { fromLonLat } from "ol/proj"
import { Point } from "ol/geom"
import "ol/ol.css"
import "../assets/css/map.css"

import { RMap, ROSM, RLayerVector, RFeature, ROverlay, RStyle } from "rlayers"
import Location from'./Location.svg'

const coords = {
  origin: [6.587933, 49.790282],
  HallAach: [6.587933, 49.790282], 
}

const HalleAach = () => {
  return (
    <RMap
      width={"100%"} height={"25vh"}
      initial={{ center: fromLonLat(coords.origin), zoom: 17 }}
    >
      <ROSM />
      <RLayerVector zIndex={10}>
      <RStyle.RStyle>
            <RStyle.RIcon src={Location} />
        </RStyle.RStyle>
        <RFeature
          geometry={new Point(fromLonLat(coords.HallAach))}
          onClick={e =>
            e.map.getView().fit(e.target.getGeometry().getExtent(), {
              duration: 250,
              maxZoom: 15
            })
          }
        >
        </RFeature>
      </RLayerVector>
    </RMap>
  )
}
export default HalleAach