import React from 'react';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import axios from 'axios';
import moment from 'moment';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getItems } from './api/backendAPI';


const List = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
        const data = await getItems();
        console.log("ici ou bien ?", data);
        setItems(data.items);
        };

        fetchItems();
    }, []);

    return (<>
        <div>
            <p>
                Alle Mitglieder
            </p>
            <Container fluid>
  
                <Row>
                    <Col>
                        <Table responsive striped bordered hover variant="dark">
                        <thead>
                            <tr>
                            <th>Vorame</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Sport</th>
                            <th>Geburtsdatum</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.surname}</td>
                                    <td>{item.email}</td>
                                    <td>{item.sport}</td>
                                    <td>{item.birthday}</td>
                                </tr>
                                ))}
                        </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>

        </>
    );

}

export default List;
