import React from 'react';
import { Typography } from '@mui/material';
import './new.css'; // Import your CSS file
import KTimage from '../../src/static/images/bodyshape.jpg';
import BSimage01 from '../../src/static/images/bodyshape01.jpg';
import BSimage02 from '../../src/static/images/bodyshape02.jpg';
import BSimage03 from '../../src/static/images/bodyshape03.jpg';
import HalleAach from './HalleAach';
import WhereCard from './Map/WhereCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Teacher from './Teachers/Teacher';
import Elke from '../../src/static/images/Elke_crop.jpg';

function Bodyshape() {
    return (
        <div className='container-sport-bodyshape'>
            <div className='section'>
                <div className="left_col">
                    {/* Desc 1 */}
                    <div id='description-kg' className='page_block module dark'>
                            <h2 className='title-category-dark'>
                            Bodyshape: Übersicht
                            </h2>
                            <p>
                            Bodyshape ist eine Form des körperlichen Trainings, das darauf abzielt, den Körper zu formen, die Muskelkraft zu verbessern und die allgemeine Fitness zu steigern. Dieser Sport kombiniert verschiedene Übungen, um verschiedene Muskelgruppen zu trainieren und eine gesunde Körperhaltung zu fördern.
                            </p>
                    </div>
                    <div id='image' className='page_block module fit'>
                        <img src={BSimage01} alt="Part 1" />
                    </div>
                    {/* Desc 2 */}
                    <div id='description-kg' className='page_block module'>
                        <h2 className='title-category'>
                        Ganzkörpertraining
                        </h2>
                        <p>
                          Bodyshape-Übungen konzentrieren sich auf das Training des gesamten Körpers, einschließlich Arme, Beine, Bauchmuskeln und Rückenmuskeln. Durch gezielte Übungen werden Muskelgruppen   gestärkt und geformt.

                        </p>
{/* Kraft und Ausdauer: Bodyshape-Training umfasst sowohl Kraftübungen als auch Ausdauertraining. Dadurch werden nicht nur Muskelkraft und Definition verbessert, sondern auch die kardiovaskuläre Fitness erhöht.

Variation und Vielseitigkeit: Der Bodyshape-Sport bietet eine Vielzahl von Übungen und Trainingsmethoden, um die Motivation zu steigern und Langeweile zu vermeiden. Dies kann Hantelübungen, Bodenübungen, Aerobic, Pilates und vieles mehr umfassen.

Anpassbarkeit: Bodyshape-Übungen können an verschiedene Fitnesslevel und körperliche Fähigkeiten angepasst werden. Sowohl Anfänger als auch Fortgeschrittene können von diesem Sport profitieren, indem sie die Intensität und Schwierigkeit der Übungen anpassen.

Gesundheitsvorteile: Neben der Verbesserung der körperlichen Fitness bietet Bodyshape auch zahlreiche gesundheitliche Vorteile, darunter eine bessere Körperhaltung, Stärkung des Herz-Kreislauf-Systems, Verbesserung der Knochengesundheit und Stressabbau. */}
                    </div>

                {/* Desc 3*/}
                <div id='description-kg' className='page_block module dark'>
                    <h2 className='title-category-dark'>
                    Kraft und Ausdauer
                    </h2>
                    <p>
                    Bodyshape-Training umfasst sowohl Kraftübungen als auch Ausdauertraining. Dadurch werden nicht nur Muskelkraft und Definition verbessert, sondern auch die kardiovaskuläre Fitness erhöht.
                    </p>
                    <div id='image' className='page_block module fit'>
                        <img src={BSimage03} alt="Part 1" />
                    </div>
                </div>

                {/* Desc 6*/}
                <div id='description-kg' className='page_block module'>
                    <h2 className='title-category'>
                    Gesundheitsvorteile
                    </h2>
                    <p>
                    Neben der Verbesserung der körperlichen Fitness bietet Bodyshape auch zahlreiche gesundheitliche Vorteile, darunter eine bessere Körperhaltung, Stärkung des Herz-Kreislauf-Systems, Verbesserung der Knochengesundheit und Stressabbau.
                    </p>
                </div>

                {/* Desc 5*/}
                <div id='description-kg' className='page_block module dark'>
                    <h2 className='title-category-dark'>
                    Anpassbarkeit
                    </h2>
                    <p>
                    Bodyshape-Übungen können an verschiedene Fitnesslevel und körperliche Fähigkeiten angepasst werden. Sowohl Anfänger als auch Fortgeschrittene können von diesem Sport profitieren, indem sie die Intensität und Schwierigkeit der Übungen anpassen.
                    </p>
                </div>

                {/* Desc 4 */}
                <div id='description-kg' className='page_block module'>
                    <h2 className='title-category'>
                    Variation und Vielseitigkeit
                    </h2>
                    <p>
                    Der Bodyshape-Sport bietet eine Vielzahl von Übungen und Trainingsmethoden, um die Motivation zu steigern und Langeweile zu vermeiden. Dies kann Hantelübungen, Bodenübungen, Aerobic, Pilates und vieles mehr umfassen.
                    </p>
                    <div id='image' className='page_block module fit'>
                        <img src={BSimage02} alt="Part 1" />
                    </div>
                </div>


                </div>

                <div className="right_col">
                    <div id='location-kg' className='page_block module map'>
                        <HalleAach />
                    </div>  
                    <div id='address-kg' className='page_block module map'>
                        <div className='location'>
                            <div className='location_icon'>
                                <LocationOnIcon style={{fontSize: '50px', color:'#f7584c' }}/>
                            </div>
                            <div className='location_info'>
                                <div className='location_title'>
                                    Ort
                                </div>
                                <div className='address'>
                                    <a class="main_color" href="https://maps.app.goo.gl/2QJp2uAzkoayFso27" target="_blank">Gemeindehalle in der Meierei, 54298 Aach</a>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div id='when-kg' className='page_block module dark'>
                        <h2 className='title-category'>
                            Kurse
                        </h2>
                        <div id='agenda-kurs' className='agenda-kurs'>
                            Frauen
                        </div>
                        <div id='agenda-item' className='agenda-hour'>
                            Dienstag 19:00-20:00
                        </div>

                    </div>
                <Teacher 
                teachers={{header: 'Kursleiterinen', coaches: [{ name: 'Elke Heid', pic: Elke, phone: '+49 Elke', email: 'elke@gmail.com' }, { name: 'Julia Simon', pic: Elke, phone: '+49 Julia', email: 'julia@gmail.com' }]}}
                />
                </div>
            </div>
        </div>
      );
}

export default Bodyshape;