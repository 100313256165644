import axios from 'axios';

const backendApi = axios.create({
  baseURL: 'https://api.lucia-vr.com',  // Replace with your backend server URL
});

export const getItems = async () => {
  const response = await backendApi.get('/api/database/items');
  console.log("on lit les items");
  return response.data;
};
