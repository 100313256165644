import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HalleAach from '../HalleAach';

export default function WhereCard() {
  return (
    <Card sx={{ minWidth: 1400 }}>
      <CardHeader
        title="Wo und wann?"
        subheader="Kinderturnen"
        color='red'
      />
      <CardContent>
        <HalleAach />
        Wann
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Contact</Button>
      </CardActions>
    </Card>
  );
}