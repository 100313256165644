// Sport.jsx
import React, { useState } from 'react';
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import './Sport.css'; // Import your CSS file
import { Link } from 'react-router-dom';


const Sport = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    return (
    <Container maxWidth="lg" className="services-container">
      <div className="services-grid">
        <div className="service-tile top">
            <Link to="/sports/foot">
                <div className="service-image football" >
                    <div className="service-text">FUSSBALL</div>
                </div>
            </Link>
        </div>
        <div className="service-tile top">
            <Link to="/sports/kinderturnen">
                <div className="service-image kinderturnen">
                    <div className="service-text">KINDERTURNEN</div>
                </div>
            </Link>
        </div>
        <div className="service-tile bottom">
            <Link to="/sports/bodyshape">
                <div className="service-image bodyshape">
                    <div className="service-text">BODYSHAPE</div>
                </div>
            </Link>
        </div>
        <div className="service-tile bottom">
            <Link to="/sports/seniorgym">
                <div className="service-image seniorgym">
                    <div className="service-text">SENIORENGYMNASTIK</div>
                </div>
            </Link>
        </div>
        {/* Add more tiles for other services */}
      </div>


    </Container>
  );
};

export default Sport;
