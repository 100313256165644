import { Button } from '@mui/material';
import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';

function WhatsAppShareButton() {
  const handleShare = () => {
    // Define the content you want to share
    const shareUrl = encodeURIComponent('https://lucia-vr/sport');
    // Construct the WhatsApp share URL with the content
    const whatsappShareUrl = `whatsapp://send?text=${shareUrl}`;

    // Open the WhatsApp share URL
    window.location.href = whatsappShareUrl;
  };

  return (
    // <button onClick={handleShare}>Share via WhatsApp</button>
    // <Button onClick={handleShare} size="small">Share</Button>
    <IconButton onClick={handleShare} aria-label="share">
      <ShareIcon />
    </IconButton>
    );
}

export default WhatsAppShareButton;