import React, { useState, useRef, useEffect } from 'react';
// import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "../CalendarStyles.css";
import "../nico.css";
import axios from 'axios';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!



const styles = {
  wrap: {
    display: "flex"
  },
  left: {
    marginRight: "10px"
  },
  main: {
    flexGrow: "1"
  }
};

const Calendar = () => {
//   const calendarRef = useRef()
//   const [startDate, setStartDate] = useState("2023-10-02")
//   const [events, setEvents] = useState(null)

//   const editEvent = async (e) => {
//     const dp = calendarRef.current.control;
//     const modal = await DayPilot.Modal.prompt("Update event text:", e.text());
//     if (!modal.result) { return; }
//     e.data.text = modal.result;
//     dp.events.update(e);
//   };

//   const [calendarConfig, setCalendarConfig] = useState({
//     viewType: "WorkWeek",
//     durationBarVisible: false,
//     cellDuration: 15,
//     theme: "nico",
//     timeRangeSelectedHandling: "Enabled",
//     onTimeRangeSelected: async args => {
//       const dp = calendarRef.current.control;
//       const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
//       dp.clearSelection();
//       if (!modal.result) { return; }
//       dp.events.add({
//         start: args.start,
//         end: args.end,
//         id: DayPilot.guid(),
//         text: modal.result
//       });
//     },
//     onEventClick: async args => {
//       await editEvent(args.e);
//     },
//     contextMenu: new DayPilot.Menu({
//       items: [
//         {
//           text: "Delete",
//           onClick: async args => {
//             const dp = calendarRef.current.control;
//             dp.events.remove(args.source);
//           },
//         },
//         {
//           text: "-"
//         },
//         {
//           text: "Edit...",
//           onClick: async args => {
//             await editEvent(args.source);
//           }
//         }
//       ]
//     }),
//     onBeforeEventRender: args => {
//       args.data.areas = [
//         {
//           top: 3,
//           right: 3,
//           width: 20,
//           height: 20,
//           symbol: "icons/daypilot.svg#minichevron-down-2",
//           fontColor: "#fff",
//           toolTip: "Show context menu",
//           action: "ContextMenu",
//         },
//         {
//           top: 3,
//           right: 25,
//           width: 20,
//           height: 20,
//           symbol: "icons/daypilot.svg#x-circle",
//           fontColor: "#fff",
//           action: "None",
//           toolTip: "Delete event",
//           onClick: async args => {
//             const dp = calendarRef.current.control;
//             dp.events.remove(args.source);
//           }
//         }
//       ];


//       const participants = args.data.participants;
//       if (participants > 0) {
//         // show one icon for each participant
//         for (let i = 0; i < participants; i++) {
//           args.data.areas.push({
//             bottom: 5,
//             right: 5 + i * 30,
//             width: 24,
//             height: 24,
//             action: "None",
//             image: `https://picsum.photos/24/24?random=${i}`,
//             style: "border-radius: 50%; border: 2px solid #fff; overflow: hidden;",
//           });
//         }
//       }
//     }
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Fetch posts from the backend
//         const response = await axios.get('http://localhost:8000/api/calendar/events');
        
//         // Set the events in the state
//         setEvents(response.data.events);
        
//         // Now that events are set, update the calendar
//         setStartDate("2023-10-02");
//       } catch (error) {
//         console.error('Error fetching events:', error);
//       }
//     };
  
//     // Call the async function to fetch and set events
//     fetchData();
//   }, []); // The empty dependency array ensures this runs once on component mount

//   useEffect(() => {
//     console.log(events);
//     // setEvents(events)
//     calendarRef.current.control.update({ startDate, events });

//   }, [events]);
  
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

// // specifi hover effects

//   const handleEventHover = (args) => {
//     args.e.data.cssClass = "hovered-event"; // Apply hover effect by adding a CSS class
//   };

//   const handleEventMouseOut = (args) => {
//     args.e.data.cssClass = null; // Remove the CSS class to remove hover effect
//   };


  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Select week
      </Button> */}
      {/* <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Select Week</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
      <div style={styles.left}>
        <DayPilotNavigator
          selectMode={"Week"}
          showMonths={3}
          skipMonths={3}
          weekStarts={1}
          startDate={startDate}
          selectionDay={startDate}
          onTimeRangeSelected={ args => {
            console.log("test", args.day);
            setStartDate(args.day)
            calendarRef.current.control.update({
              startDate: args.day
            });
          }}
        />
      </div>
        </Offcanvas.Body>
      </Offcanvas>
    <div style={styles.wrap}>
      <div style={styles.main}>
        <DayPilotCalendar
          {...calendarConfig}
          ref={calendarRef}
        />
      </div>
    </div> */}
    <div>
    <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
      />
    </div>
    </>
  );
}

export default Calendar;