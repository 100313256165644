// import { auth } from "../services/firebase";
// import Card from "react-bootstrap/Card";
// import React from "react";
// import Button from "react-bootstrap/Button";
// import { useState, useEffect } from "react";
// import axios from "axios";

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

// import Form from 'react-bootstrap/Form';
// import Table from 'react-bootstrap/Table';
// import EditableTable from '../components/EditableTable';


// import "../App.css";

// const Profile = ({ user }) => {

// 	const [firstName, setFirstName] = useState('');
// 	const [lastName, setLastName] = useState('');
// 	const [email, setEmail] = useState('');
// 	const [sport, setSport] = useState('');
// 	const [birthday, setBirthday] = useState('');

//     const [dataBack, setDataBack] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const dataSend = {'username': user.displayName, 'email': user.email}
//     useEffect(() => {
//             axios
//         .post("http://127.0.0.1:8000/api/database/register", dataSend)
//         .then((response) => {
//         console.log(response.data.res);
        
//         if (response.data.res === "redefine")
//         { console.log("redefine"); } else { console.log("created successful"); }
//         })
//         .catch((error) => {
//         alert(error);
//         });
//     }, []); // The empty array [] as the second argument makes this effect run once, like componentDidMount

//     useEffect(() => {

//         // Look at current logged user
//         const apiUrl = `http://localhost:8000/api/database/users/${user.email}`;
//         // Make an API request to your FastAPI backend to fetch data from MongoDB
//         axios.get(apiUrl)
//             .then((response) => {
//                 let dataDisplay = response.data;
//                 const displayDate = new Date(response.data.birthday)
//                 const options = { year: 'numeric', month: 'long', day: 'numeric' };

//                 dataDisplay.birthday = displayDate.toLocaleDateString('de-DE', options);
//                 console.log(dataDisplay);
//                 setDataBack(dataDisplay);
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 console.log("NOK", error);
//             setError(error);
//             setLoading(false);
//             });
//     }, []); // The empty array [] as the second argument makes this effect run once, like componentDidMount

	
// 	const handleSubmit = async (e) => {
// 		e.preventDefault();
// 		try {
// 			console.log("birth >>>", birthday);
//             console.log("infos :", user.displayName, firstName, lastName, email, sport);
// 		await axios.post('http://localhost:8000/api/database/users', { username: user.displayName, name: firstName, surname: lastName, email: email, sport: sport, birthday: new Date(birthday)});
// 		alert('User updated successfully');
//         window.location.reload();
// 		} catch (error) {
// 		console.error(error);
// 		alert('An error occurred');
// 		}
// 	};

//   return (
//     <>
//     <div className="container-fluid" style={{ marginTop: "10%" }}>
//       <div className="row">
//           <Card.Body>
//             <Card.Title>Welcome</Card.Title>
//             <Card.Subtitle className="mb-2 text-muted">
//               {user.displayName}
//             </Card.Subtitle>
//             <img src={user.photoURL} alt="" />
//             <Button
//             style={{margin: '5%'}}
//             variant="outline-danger"
//             type="submit"
//             onClick={() => auth.signOut()}
//           >
//             Sign Out
//           </Button>
//           </Card.Body>
//       </div>
//       <div>
//                 <Table striped bordered hover responsive="md">
//                     <thead>
//                         <tr>
//                         <th>Username</th>
//                         <td>{dataBack.username}</td>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                         <th>Vorname</th>
//                         <td>{dataBack.name}</td>
//                         </tr>

//                         <tr>
//                         <th>Nachname</th>
//                         <td>{dataBack.surname}</td>
//                         </tr>

//                         <tr>
//                         <th>E-mail</th>
//                         <td>{dataBack.email}</td>
//                         </tr>

//                         <tr>
//                         <th>Geburtsdatum</th>
//                         <td>{dataBack.birthday}</td>
//                         </tr>

//                         <tr>
//                         <th>Sport</th>
//                         <td>{dataBack.sport}</td>
//                         </tr>
//                     </tbody>
//                 </Table>
// 				</div>
//     </div>

//     {/* <Container>
//             <Row>
//                 <Col><h3>Profil ausfüllen</h3></Col>
//             </Row>
//        </Container>      
//             <div className="d-flex justify-content-center">
//                 <Card  style={{ width: '1000px' }}>
//                 <Card.Body>
//                 <Form>
// 				<Form.Group className="mb-3" controlId="register.FirstName">
// 					<Form.Label>Vorname (*)</Form.Label>
// 					<Form.Control type="text" placeholder="" onChange={(e) => setFirstName(e.target.value)} />
// 				</Form.Group>
// 				<Form.Group className="mb-3" controlId="register.LastName">
// 					<Form.Label>Nachname (*)</Form.Label>
// 					<Form.Control type="text" placeholder="" onChange={(e) => setLastName(e.target.value)} />
// 				</Form.Group>

// 				<Form.Group className="mb-3" controlId="register.Email">
// 					<Form.Label>Ihre E-Mail-Adresse (*)</Form.Label>
// 					<Form.Control type="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)}/>
// 				</Form.Group>

// 				<Form.Group className="mb-3" controlId="register.Age">
// 					<Form.Label>Geburtsdatum</Form.Label>
// 					<Form.Control type="date" onChange={(e) => setBirthday(e.target.value)}/>
// 				</Form.Group> 
// 				<Form.Group className="mb-3" controlId="register.Sport">
// 					<Form.Label>Sport</Form.Label>
// 					<Form.Select aria-label="select-sport"
// 						value={sport}
// 						onChange={(e) => setSport(e.currentTarget.value)}
// 					>
// 						<option>Sport wählen</option>
// 						<option value="Fussball">Fußball</option>
// 						<option value="Kinderturnen">Kinderturnen</option>
// 						<option value="Others">Sport de vieux</option>
// 					</Form.Select>
// 				</Form.Group>

// 				<Button type="submit" onClick={(e) => handleSubmit(e)}>Senden</Button>

//                     </Form>            
//                 </Card.Body>
//                 </Card>
//             </div> */}
//             <div className="d-flex justify-content-center">
//                 <Card  style={{ width: '1000px' }}>
//                 <Card.Body>
//                   <EditableTable user={dataBack}/>
//                 </Card.Body>
//                 </Card>
//             </div>

//     </>
//   );
// };

// export default Profile;



import React, { Component } from 'react';
import axios from 'axios';
import EditableTable from '../components/EditableTable';
import { auth } from "../services/firebase";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
    };
    this.state = {
      userAuth: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const userExists = /* Check if the user exists, e.g., by checking local storage or state */
    this.setState({ userAuth: user });

    // if (!userExists) {
      // Create a new user
      this.createUser(user)
        .then(() => {
          // Fetch user data after creating the user
          this.fetchUserData(user);
        })
        .catch((error) => {
          // Handle errors from creating the user
          console.error(error);
        });
    // } else {
    //   // User already exists, fetch user data
    //   this.fetchUserData();
    // }
  }

  createUser(user) {
    // Make an Axios POST request to create a new user
    const dataSend = {'username': user.displayName, 'email': user.email}
    return axios.post("http://127.0.0.1:8000/api/database/register", dataSend)
          .then((response) => {
            console.log(response.data.res);
            if (response.data.res === "redefine")
            { console.log("redefine"); } else { console.log("created successful"); }
            })
          .catch((error) => {
            return Promise.reject(error);
            alert(error);
          });

  }

  fetchUserData(user) {
    // Make an Axios GET request to fetch user data based on the user's identifier (e.g., uid)
    const apiUrl = `http://localhost:8000/api/database/users/${user.email}`;
    axios.get(apiUrl)
      .then((response) => {
        this.setState({ userData: response.data });
      })
      .catch((error) => {
        // Handle errors from fetching user data
        console.error(error);
      });
  }

  render() {
    const { userData } = this.state;
    const userAuth = this.props.user;
    console.log("userAuth >>>", userAuth.displayName);
    console.log("userAuth >>>", userData);

    return (
      <>
     <div className="container-fluid" style={{ marginTop: "1%" , marginLeft: "1%", marginRight: "20%"}}>
      <Card>

       <div className="row" style={{ marginTop: "2.5%" , marginLeft: "2%"}}>
           <Card.Body>
             <Card.Title>Welcome</Card.Title>
             <Card.Subtitle className="mb-2 text-muted">
               {userData && userData.username}
             </Card.Subtitle>
             { userAuth.photoURL ? (
               <img id="profilePic" src={userAuth.photoURL} alt="Profile picture"/>
               ) : (
               <img id="profilePic" src="https://img.freepik.com/free-psd/3d-icon-social-media-app_23-2150049569.jpg?w=826&t=st=1696425188~exp=1696425788~hmac=a1c9727cceb023693d6edd6d0e131170a96a20023728cc50309ecb50c2f2b0ec" width="100" alt="Profile picture"/>
             )}
             <Button
             style={{margin: '5%'}}
             variant="outline-danger"
             type="submit"
             onClick={() => auth.signOut()}
             >
             Sign Out
           </Button>
           </Card.Body>
       </div>

        <div style={{ marginTop: "2.%" , marginLeft: "2%", marginBottom: "2%"}}>
          {userData && <EditableTable user={userData} />}
        </div>
      </Card>
      </div>
      </>
    );
  }
}

export default Profile;
