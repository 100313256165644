import React from "react";

const Unauthorized = () => {
	return (
		<div className="center-div">
			<h1>
				You are not authorized to view the selected page
			</h1>
		</div>
	);
};

export default Unauthorized;
