import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pagination } from 'react-bootstrap';
import  Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import PostsPagination from '../components/PostPagination';

const News = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(2);

  const serverUrl = "https://api.lucia-vr.com";

  useEffect(() => {
    // Fetch posts from the backend
    axios.get('https://api.lucia-vr.com/api/news/posts')
      .then(response => {
        separeParagraphs(response.data.posts)
        setPosts(response.data.posts);
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  }, []);


  // Get current posts based on pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPosts = posts.length;
  // const currentPosts = currentPostsOld.reverse()

  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const separeParagraphs = (items) => {
    items.map((item) => (
      console.log("ta soeur ", item.content.split(",|,"))
      ))
  }
  
  return (
    <div className="blog">
      {/* <Container fluid>
      <div className="pagination-container d-flex justify-content-center flex-wrap">
        <Pagination>
          {Array.from({ length: Math.ceil(posts.length / postsPerPage) }).map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>      
      </Container> */}
      <PostsPagination
              totalPosts={totalPosts}
              postsPerPage={postsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
      /> 
      <div className='leftcolumn'>
        {currentPosts.map(post => (
          <div key={post.id} className="blogitem">
            <h2>{post.title}</h2>
            <h5>Description & date</h5>
            {
              post.content.slice(0, -2).split(",|,").map((item) => (
                <p className='p-blog'>{item}</p>
              ))
            }
            {
              post.images && (
                  <img
                    srcSet={`${serverUrl}/uploads/${post.images} 320w, ${serverUrl}/uploads/${post.images} 680w, ${serverUrl}/uploads/${post.images} 960w, ${serverUrl}/uploads/${post.images} 1980w`}
                    src={`${serverUrl}/uploads/${post.images}`}
                    alt="marche pas"
                    style={{
                      maxWidth: '75%',
                      height: 'auto',
                    }}
                  />
              )
            }
          </div>
        ))}
      </div>
      <div class="rightcolumn">
        <div class="blogitem">
          <h2>About Me</h2>
          <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
        </div>
        <div class="blogitem">
          <h3>Popular Post</h3>
        </div>
        <div class="blogitem">
          <h3>Follow Me</h3>
          <p>Some text..</p>
        </div>
      </div>
    </div>
  );
};


export default News;
