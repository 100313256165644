import React from 'react';
import './new.css'; // Import your CSS file
import Pyramid from '../../src/static/images/football01.jpg';
import SportplatzButzweiler from './SportplatzButzweiler';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Teacher from './Teachers/Teacher';
import HeikoPic from '../../src/static/images/Heiko_unofficial.jpg';
import Waltraud from './Teachers/Waltraud';
import Heiko from './Teachers/Heiko';

function Kinderturnen() {
    return (
        <div className='container-sport-football'>
            <div className='section'>
                <div className="left_col">
                    {/* Desc 1 */}
                    <div id='description-kg' className='page_block module dark'>
                            <h2 className='title-category-dark'>
                                Fußball
                            </h2>
                            <p>
                                Diese Stunde fördert bei den Allerkleinsten ab Laufalter spielerich die Entwicklung von Körper und Geist, Geschicklichkeit, Balance, Körpererfahrung und ein wenig Selbstvertrauen in das eigene Handeln.
                            </p>
                    <div id='image' className='page_block module fit'>
                        <img src={Pyramid} alt="Part 1" />
                    </div>
                    </div>
                    {/* Desc 2 */}
                    <div id='description-kg' className='page_block module'>
                        <h2 className='title-category'>
                            Kinderturnen 2,5-3,5 Jahre
                        </h2>
                        <p>
                        In dieser Gruppe wird Spaß und Freude an der Bewegung im Spiel vermittelt. Kleinere Geschicklichkeitsübungen am Anfang der Stunde werden als Aufgabenstellung mit oder ohne Hilfe eines Elternteils erarbeitet. Danach ist selbstverständlich Klettern und Balancieren angesagt.
                        </p>
                    </div>

                {/* Desc 3*/}
                <div id='description-kg' className='page_block module dark'>
                    <h2 className='title-category-dark'>
                        Kinderturnen 3,5-4,5 Jahre
                    </h2>
                    <p>
                    Damit Sport allen Kindern Spaß macht werden in dieser Stunde verschiedene Übungen spielerisch erarbeitet. Durch unterschiedliche Bewegungsanforderungen wird die Konzentration und Ausdauer verbessert. Die Übungen und Spiele in der Gruppe fördern gleichzeitig auch schon die Anfänge des Sozialverhaltens der Kinder.
                    </p>
                </div>
                <div id='image' className='page_block module fit'>
                    <img src={Pyramid} alt="Part 1" />
                </div>
                {/* Desc 2 */}
                <div id='description-kg' className='page_block module'>
                    <h2 className='title-category'>
                        Kinderturnen 4,5 Jahre bis Schulalter
                    </h2>
                    <p>
                    In dieser Stunde werden vorrangig die sozialen Kompetenzen gefördert und geschult. Beispielsweise lernen die Kinder beim Spielen/Turnen mit Gleichaltrigen nachzugeben und zu kooperieren. Die motorischen Fähigkeiten z.B.hüpfen, werfen oder balancieren stehen dabei auch im Fokus und fördern den Gleichgewichtssinn. Auch die kognitiven Fähigkeiten wie  z.B. Konzentrations- und Kooperationsfähigkeit wird trainiert.
                    </p>
                </div>

                </div>

                <div className="right_col">
                    <div id='location-kg' className='page_block module map'>
                        <SportplatzButzweiler />
                    </div>  
                    <div id='address-kg' className='page_block module map'>
                        <div className='location'>
                            <div className='location_icon'>
                                <LocationOnIcon style={{fontSize: '50px', color:'#f7584c' }}/>
                            </div>
                            <div className='location_info'>
                                <div className='location_title'>
                                    Ort
                                </div>
                                <div className='address'>
                                    <a class="main_color" href="https://maps.app.goo.gl/FxvuMULvPztDJLDbA" target="_blank"><p className='p-ort'>Fußballplatz Butzweiler</p><p className='p-ort'>Trierer Str. 26, 54309 Newel</p></a>
                                </div>
                            </div>
                        </div>  
                    </div>
                    <div id='when-kg' className='page_block module dark'>
                        <h2 className='title-category'>
                            Kurse
                        </h2>
                        <div id='agenda-kurs' className='agenda-kurs'>
                            Kleinkinderturnen
                        </div>
                        <div id='agenda-item' className='agenda-hour'>
                            Montag 15:00-16:00
                        </div>
                        <div id='agenda-kurs' className='agenda-kurs'>
                            Eltern-Kind-Turnen
                        </div>
                        <div id='agenda-item' className='agenda-hour'>
                            Montag 16:00-17:00
                        </div>
                        <div id='agenda-kurs' className='agenda-kurs'>
                            Kinderturnen (4-6 Jahre)
                        </div>
                        <div id='agenda-item' className='agenda-hour'>
                            Dienstag 16:00-17:00
                        </div>
                    </div>
                <Teacher 
                teachers={{header: 'Kontakt', coaches: [{ name: 'Heiko Koch', pic: HeikoPic, phone: '0162 - 289 206 8', email: 'heiko@gmail.com', description: <Heiko />}]}}
                />
                </div>
            </div>
        </div>
      );
}

export default Kinderturnen;