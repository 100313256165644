import React from 'react';

const Home = () => {
	return (
		<div>
			<h1>Willkommen in Aach</h1>
		</div>
	);
};

export default Home;
